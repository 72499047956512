import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { LowerCasePipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, filter, of, shareReplay, switchMap, takeUntil } from 'rxjs';
import { GlobalService } from 'src/_service/GlobalService';
import { environment } from '../../_environments/environment';
import { CustomerAllergen } from '../../_model/customer';
import { Allergen, Lifestyle, Recipe } from '../../_model/recipe';
import { BottomdrawerdialogData, TableParameters, UrlParameters } from '../../_model/shared';
import { AuthService } from '../../_service/AuthService';
import { DeliverydayService } from '../../_service/DeliverydayService';
import { LifestyleService } from '../../_service/LifestyleService';
import { LinkService } from '../../_service/LinkService';
import { RecipeService } from '../../_service/RecipeService';
import { SubscriptionService } from '../../_service/SubscriptionService';
import { WINDOW } from '../../_service/WindowService';
import { collapse } from '../../_theme/animations';
import { BottomdrawerDialogComponent } from '../../sharedModule/bottomdrawerDialog/bottomdrawerDialog.component';
import { ChipComponent } from '../../sharedModule/chip/chip.component';
import { FooterComponent } from '../../sharedModule/footer/footer.component';
import { CapitalizeFirstPipe } from '../../sharedModule/pipe/capitalizefirst.pipe';
import { RecipecardComponent } from '../../sharedModule/recipecard/recipecard.component';
import { RecipelistComponent } from '../../sharedModule/recipelist/recipelist.component';
import { ToggleComponent } from '../../sharedModule/toggle/toggle.component';
import { LoaderComponent } from '../../sharedModule/loader/loader.component';
import { CustomerService } from '../../_service/CustomerService';

@Component({
  selector: 'app-allrecipes',
  templateUrl: './allrecipes.component.html',
  styleUrls: ['./allrecipes.component.scss'],
  animations: [collapse],
  standalone: true,
  providers: [DeliverydayService, SubscriptionService],
  imports: [
    NgIf, RouterLink, NgFor, NgSwitch, NgSwitchCase, NgTemplateOutlet,
    ReactiveFormsModule, FormsModule, TranslateModule,
    ToggleComponent, ChipComponent, RecipelistComponent, RecipecardComponent, FooterComponent, LoaderComponent,
    MatSelect, MatOption,
    LowerCasePipe, CapitalizeFirstPipe]
})
export class AllrecipesComponent implements OnInit, OnDestroy {

  _env = environment;

  nextThursday: any;

  recipes: Recipe[] = [];
  lifestyleRecipes: Recipe[] = [];
  searchedRecipes: Recipe[] = [];

  @ViewChild('bottomdrawerTemplateRef', { read: TemplateRef }) bottomdrawerTemplate: TemplateRef<any>;

  bottomsheetRef: MatBottomSheetRef;
  bottomSheetOpened = false;
  currentUrl: string;

  dialogRef: any;

  displaytype = 'LIST' //'LIFESTYLE', 'RECIPESEARCH', 'INGREDIENTSEARCH'


  lifestyles: Lifestyle[];
  allergens: string[];

  shownLifestyle: Lifestyle;
  shownLifestyleid: number;
  shownRecipeCnt = 0;

  allergensCtrl = new FormControl([]);

  filteredRecipes: Observable<Recipe[]>;
  filteredIngredientRecipes: Observable<Recipe[]>;
  searchRecipe = new FormControl('')
  recipeSearchLoading = false;
  recipesloading = false;

  shownpage = 0;
  totalpages = 1;
  private recipeLifestyleSearch: Subject<any> = new Subject();
  private recipeSearch: Subject<any> = new Subject();

  searchValue = new FormControl('')
  searchedValue = new FormControl('');

  ingredientSearchActive = false;
  ingredientCtrl = new FormControl();
  ingredients = new FormControl([]) //string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ingredientShownpage = 0;
  ingredientTotalpages = 1;
  scrollStrategy: ScrollStrategy

  private unsubscribe = new Subject<void>();
  constructor(
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    private _customerService: CustomerService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private _lifestyleService: LifestyleService,
    private _recipeService: RecipeService,
    private _authService: AuthService,
    private _globalService: GlobalService,
    // private _translate: TranslateService,
    private _sso: ScrollStrategyOptions,
    private _linkService: LinkService,
    private _translate: TranslateService,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngOnInit() {
    this.scrollStrategy = this._sso.noop();

    //console.log('ngoninit')
    this._globalService.setTranslatedTitle('allrecipes.allrecipes');

    this._linkService.setTranslatedStaticpageSeoData('allrecipes.metatitle', 'allrecipes.metadescription', '/assets/images/ekomenu_webshop.webp')

    this._lifestyleService.getLifestyleList().subscribe(result => {
      this.lifestyles = result;
      if (this.activatedRoute.snapshot.params.lifestyle)
        this.setActiveLifestyle(this.activatedRoute.snapshot.params.lifestyle);
    });

    const allergens = this._customerService.getAllergenFormControls.value.map((l: CustomerAllergen) => l.name);
    this.allergensCtrl.patchValue(allergens);
    this.allergensCtrl.valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((value: Allergen[]) => {
      this._customerService.getAllergenFormControls.clear();
      this._customerService.getAllergenFlowFormControls.clear();
      
      value.forEach(val => {
        this._customerService.addAllergen(new CustomerAllergen(null, val));
      })

    })
    this.allergens = this._recipeService.getAllergens();

    this.searchedValue.valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(result => {
      if (result)
        this.ingredients.setValue(null, { emitEvent: false });
      if (!result && this.displaytype == 'RECIPESEARCH') {
        this.displaytype = this.shownLifestyle ? 'LIFESTYLE' : 'LIST';
      } else if (result) {
        this.displaytype = 'RECIPESEARCH'
      }
    })
    this.ingredients.valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(result => {
      if ((result && result.length > 0))
        this.searchedValue.setValue(null, { emitEvent: false });
      if ((!result || result.length === 0) && this.displaytype == 'INGREDIENTSEARCH') {
        this.displaytype = this.shownLifestyle ? 'LIFESTYLE' : 'LIST';
      } else if (result) {
        this.displaytype = 'INGREDIENTSEARCH'
      }
    })


    const dateToday = new Date();
    const dateTodayWeekday = formatDate(dateToday, 'c', ɵDEFAULT_LOCALE_ID);

    let dateoffset = 4 - Number(dateTodayWeekday);
    if (dateoffset > 0) {
      this.nextThursday = formatDate(new Date(dateToday.getTime() + dateoffset * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    } else {
      this.nextThursday = formatDate(new Date(dateToday.getTime() + (7 + dateoffset) * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    }

    this.recipeLifestyleSearch.pipe(
      takeUntil(this.unsubscribe),
      shareReplay(),
      filter(() => { return (this.shownpage <= this.totalpages) && this.nextThursday }),
      switchMap(tblparams => {
        this.recipesloading = true;
        //console.log('getting extra recipes')
        return this._recipeService.getRecipeTableByPeriod(tblparams as UrlParameters, this.nextThursday, this.nextThursday);
      })
    ).subscribe(
      result => {
        if (!result) {
          this.recipesloading = false;
          return;
        }

        this.shownpage++;
        this.totalpages = Number(result.totalPages);
        if (this.shownpage >= this.totalpages) {
          this.shownpage = 0;
          this.totalpages = 1;
          this.nextThursday = formatDate(new Date(new Date(this.nextThursday).getTime() + 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
        }

        this.recipes = this.recipes || [];
        if (result.content)
          this.lifestyleRecipes = [...new Map([...this.lifestyleRecipes, ...result.content].map(item => [item['id'], item])).values()];
        if (this.displaytype === 'LIST' || this.displaytype === 'LIFESTYLE')
          this.recipes = this.lifestyleRecipes;

        //needs timeout to overcome error in tests
        setTimeout(() => {
          this.recipesloading = false;
        })
      }, error => {
        this.recipesloading = false;
      })

    const tblparams = new TableParameters(0, 50, 'id', 'desc');
    const params = tblparams.getUrlParameters();

    this.recipeLifestyleSearch.next(params);


    this.recipeSearch.pipe(
      takeUntil(this.unsubscribe),
      shareReplay(),
      filter(() => {
        return (this.ingredientShownpage <= this.ingredientTotalpages)
      }),
      switchMap((tblparams: TableParameters) => {

        this.recipesloading = true;
        //console.log('getting extra recipes')
        if (this.displaytype === 'RECIPESEARCH')
          return this._recipeService.getRecipeTableByName(tblparams, this.searchedValue.value);
        if (this.displaytype === 'INGREDIENTSEARCH')
          return this._recipeService.getRecipeListByIngredients(tblparams, this.ingredients.value);
        return of(null)
      })
    ).subscribe(
      result => {
        if (!result) {
          this.recipesloading = false;
          return;
        }

        this.ingredientShownpage++;
        this.ingredientTotalpages = Number(result.totalPages);
        this.recipes = this.recipes || [];
        if(result.content)
          this.searchedRecipes = [...new Map([...this.searchedRecipes, ...result.content].map(item => [item['id'], item])).values()];
        if (this.displaytype === 'RECIPESEARCH' || this.displaytype === 'INGREDIENTSEARCH')
          this.recipes = this.searchedRecipes;

        this.recipesloading = false;
      }, error => {
        console.log(error);
        this.recipesloading = false;
      })

    this.ingredients.valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(result => {
      this.recipes = null;
      this.searchedRecipes = [];
      this.ingredientShownpage = 0;
      this.ingredientTotalpages = 1;
      if (result) {
        result.forEach(r => { r = r.trim(); })
        let params = new UrlParameters();
        params.addParameter('i', result ? result.join(',') : null)
        this._router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: params.toObject(false),
        })
      } else {
        this._router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { i: null },
          queryParamsHandling: 'merge'

        })
      }

    })

    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.lifestyle) {
        this.setActiveLifestyle(params.lifestyle);
      }
    })

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.q) {

        this.searchedValue.setValue(decodeURI(params.q));
        if (this.searchRecipe.value !== params.q)
          this.searchRecipe.setValue(decodeURI(params.q));

        this.recipes = null;
        this.searchedRecipes = [];
        this.shownpage = 0;
        this.totalpages = 1;
        const tblparams = new TableParameters(0, 10, 'id', 'desc');
        this.recipeSearch.next(tblparams);
      }
      else {
        this.searchedValue.setValue(null);
        this.searchRecipe.setValue(null);
      }

      if (params.i) {
        this.ingredientSearchActive = true;
        let ingredients = decodeURI(params.i).split(",");
        if (this.ingredients.value !== ingredients) {
          const values = [];
          ingredients.forEach(val => {
            values.push(val)
          })
          this.ingredients.setValue(values);
        }
        this.recipes = null;
        this.searchedRecipes = [];
        this.ingredientShownpage = 0;
        this.ingredientTotalpages = 1;
        const tblparams = new TableParameters(0, 10, 'id', 'desc');
        this.recipeSearch.next(tblparams);
      } else {
        this.ingredients.setValue(null);
      }

      if (!params.q && !params.i) {
        this.recipes = this.lifestyleRecipes;
      }
    })

    setTimeout(() => {
      this.openBottomsheet();
    }, 100);


    this._globalService.addToDatalayer('virtualPageLoaded')

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    if (this.bottomSheetOpened) {
      this.closeBottomsheet();
    }
    this._linkService.removeLinkTag('rel="alternate"')
    this._linkService.removeLinkTag('rel="canonical"')
    this._linkService.removeMetaData();
  }

  loadNextRecipes() {
    setTimeout(() => {
      let tblparams;
      if (this.displaytype == 'RECIPESEARCH' || this.displaytype == 'INGREDIENTSEARCH') {
        tblparams = new TableParameters(this.ingredientShownpage, 10, 'id', 'asc');
        this.recipeSearch.next(tblparams);
      } else {
        tblparams = new TableParameters(this.shownpage, 10, 'id', 'asc');
        const params = tblparams.getUrlParameters();

        this.recipeLifestyleSearch.next(params);

      }
    })
  }

  onEnter() {
    this.ingredientShownpage = 0;
    this.ingredientTotalpages = 1;

    this.searchedRecipes = [];
    this.recipes = null;
    this.searchedValue.setValue(this.searchRecipe.value);

    let params = new UrlParameters();
    params.addParameter('q', this.searchRecipe.value)
    this._router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params.toObject(),
    })
    const tblparams = new TableParameters(0, 15, 'id', 'desc');
    this.recipeSearch.next(tblparams)

  }

  setActiveLifestyle(lifestylename: string) {
    if (!this.lifestyles) return;
    const style = this.lifestyles.filter(f => f.name.replace(' ', '-').toLowerCase() === lifestylename)[0];
    if (style) {
      if (this.displaytype == 'LIST')
        this.displaytype = 'LIFESTYLE'
      this.shownLifestyle = style;
      this.shownLifestyleid = style.id;
    } else {
      console.log('lifestyle not found', style)
      this.shownLifestyle = null;
      this.shownLifestyleid = null;
      this.displaytype = "LIST";
    }
  }
  navigateToLifestyle() {
    this.shownLifestyle = this.lifestyles.find(l => l.id == this.shownLifestyleid);
    if (!this.shownLifestyle) {
      this._router.navigate(['recipes'], { queryParamsHandling: 'merge' })
    } else {
      this._router.navigate(['recipes', this.shownLifestyle.name.replace(' ', '-')], { queryParamsHandling: 'merge' })
    }
  }
  // Allergens
  addAllergen(allergen: Allergen) {
    const allergens = this.allergensCtrl.value;
    allergens.push(allergen);
    this.allergensCtrl.setValue(allergens);
  }
  toggleAllergen(allergen: Allergen| string) {
    if (this.isActiveAllergen(allergen)) {
      this.deleteAllergen(allergen);
    } else {
      this.addAllergen(Allergen[allergen]);
    }
  }
  deleteAllergen(allergen: string) {
    const allergens = this.allergensCtrl.value;
    const index = allergens.indexOf(allergen);
    allergens.splice(index, 1);
    this.allergensCtrl.setValue(allergens);

  }
  isActiveAllergen(allergen: string): boolean {
    return this.allergensCtrl.value.indexOf(allergen) >= 0
  }

  slideleft(value: number) {
    let val = document.querySelectorAll("#recipelist-" + value)[0];
    //console.log(val)
    if (val)
      val.children[0].scrollTo({ left: (val.children[0].scrollLeft + (screen.width * 0.75)), behavior: 'smooth' });
  }
  slideright(value: number) {
    let val = document.querySelectorAll("#recipelist-" + value)[0];
    //console.log(val)
    if (val)
      val.children[0].scrollTo({ left: (val.children[0].scrollLeft - (screen.width * 0.75)), behavior: 'smooth' });
  }


  add(): void {
    const value = (this.ingredientCtrl.value || '').trim();
    // Add our fruit
    if (value) {
      this.ingredientShownpage = 0;
      this.ingredientTotalpages = 1;

      let values = this.ingredients.value || [];
      values.push(value);
      this.ingredients.setValue(values);
      this.recipes = null;
    } else {
      this.recipes = null;
      let values = this.ingredients.value
      this.ingredients.setValue(values);
    }

    // Clear the input value
    //event.chipInput!.clear();

    this.ingredientCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.ingredients.value.indexOf(fruit);

    if (index >= 0) {
      this.ingredientShownpage = 0;
      this.ingredientTotalpages = 1;

      let values = this.ingredients.value
      values.splice(index, 1)
      this.ingredients.setValue(values);
    }
  }

  openBottomsheet() {
    // makes sure that the bottom bar is not openened when the ordersidenav is opened in a small window
    this.createBottomSheet();

  }
  closeBottomsheet() {
    if (this.bottomsheetRef) {
      this.bottomsheetRef.dismiss();
      this.bottomSheetOpened = false;
    }
  }
  createBottomSheet() {
    if (!this.bottomSheetOpened && !this._authService.isAuthenticated()) {
      this.bottomSheetOpened = true;
      this.bottomsheetRef = this._bottomSheet.open(BottomdrawerDialogComponent, {
        // , closeOnNavigation: true // <-- doesnt work, closes also on parameter changes
        data: new BottomdrawerdialogData(this.bottomdrawerTemplate), hasBackdrop: false, disableClose: true, closeOnNavigation: false,
        scrollStrategy: this.scrollStrategy
      });
    }
  }





}
