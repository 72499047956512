import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetConfig, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AddressService } from './_service/AddressService';
import { AuthGuardService } from './_service/AuthGuardService';
import { AuthInterceptor } from './_service/AuthInterceptor';
import { AuthService } from './_service/AuthService';
import { BoxService } from './_service/BoxService';
import { CmsService } from './_service/CmsService';
import { CouponcodeService } from './_service/CouponcodeService';
import { DeliverydayService } from './_service/DeliverydayService';
import { DrawerService } from './_service/DrawerService';
import { FaqService } from './_service/FaqService';
import { LifestyleService } from './_service/LifestyleService';
import { LinkService } from './_service/LinkService';
import { PricingService } from './_service/PricingService';
import { ProductService } from './_service/ProductService';
import { RecipeService } from './_service/RecipeService';
import { StaticpageService } from './_service/StaticpageService';
import { WINDOW_PROVIDERS } from './_service/WindowService';
import { AppRoutingModule, routes } from './appModule/app-routing.module';
import { LogoutResolver } from './appModule/logout/logout.resolver';
import { LocalizedDatePipe } from './sharedModule/pipe/localizedDate.pipe';
import { SharedModule } from './sharedModule/shared.module';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { environment } from './_environments/environment';
import { GlobalService } from './_service/GlobalService';


export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export class TranslateHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {

    if (!environment.production ) {
     console.log('*** Missing translations for:', params.translateService.currentLang, "'" + params.key + "'");
    }
    return params.key; // here u can return translation
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideRouter(routes),
    provideClientHydration(withNoHttpTransferCache()),
    provideAnimations(),

    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      SharedModule,
      MatInputModule,
      MatSelectModule,
      MatBottomSheetModule,
      MatDialogModule,
      ReactiveFormsModule,
      FormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler
        },
        isolate: false,
        missingTranslationHandler: [{ provide: MissingTranslationHandler, useClass: TranslateHandler }]
      })
    ),
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        // locales: ['nl', 'en'],
        biDiSupport: false,
        disablePluralKeyChecks: false,
        formatters: undefined,
        locales: undefined,
        strictNumberSign: false
      }
    },

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // needs these dialog things to show the dialog in the lifestyle component...
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: ['rounded-lg', 'bg-white'], width: '475px', maxWidth: '80vw', hasBackdrop: true } },
    { provide: MatDialogRef, useValue: {} },
    MatDialogConfig,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    MatBottomSheetConfig,
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    GlobalService,
    CookieService,
    AuthService,
    AuthGuardService,
    LogoutResolver,
    FormBuilder,
    StaticpageService,
    LinkService,
    DrawerService,
    DeliverydayService,
    AddressService,
    ProductService,
    BoxService,
    RecipeService,
    LifestyleService,
    FaqService,
    PricingService,
    CouponcodeService,
    CmsService,
    LocalizedDatePipe,
    WINDOW_PROVIDERS,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ]
};
