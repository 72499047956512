<div class="bg-e-white min-h-[81px] z-[12]  border-b border-[#8a8987] sticky top-0 flex  flex-col w-full justify-between shadow-md md:shadow-none">

  <div class="flex px-[25px]">
    <div class="basis-[33%] flex">
      <button (click)="toggleDrawer()">
        <img src="/assets/images/icons/hamburger.svg" width="27" height="17" alt="menu hamburger" />
      </button>
      @if(show_userbuttons){
      @if((isAuthenticated | async)){
      <!-- //it didnt work without an empty if -->
      }@else{
      <a [routerLink]="['/menus']" class="button-base ms-5 hidden self-center rounded-full md:block">
        {{ 'mpm.find_your_menu' | translate }}
      </a>
      }
      }
    </div>

    <div class="basis-[50%] flex justify-center">
      @if(show_userbuttons){
      <a class="py-[17px] text-center no-underline md:py-[30px] h-20 md:h-36 " routerLink="/" aria-label="Homepage">
        <img alt="Ekomenu logo" class="w-[107px] h-[23px] mx-auto md:w-[245px] md:h-[53px]" [src]="env.logo" />
        <span class="text-e-dark-green font-sans text-xs font-medium uppercase tracking-widest md:text-sm">{{env.subtitle | translate }}</span>
      </a>
      }@else{
      <div class="py-[17px] text-center md:py-[30px] h-20 md:h-36 ">
        <img alt="Ekomenu logo" class="w-[107px] h-[23px] mx-auto md:w-[245px] md:h-[53px]" [src]="env.logo" />
        <span class="text-e-dark-green font-sans text-xs font-medium uppercase tracking-widest md:text-sm">{{env.subtitle | translate }}</span>
      </div>
      }
    </div>


    <div class="basis-[33%] flex flex-wrap content-center items-center justify-end">
      <div class="grid-cols-3 grid gap-1 md:gap-2">
        <div>
          <a routerLinkActive="text-white bg-e-dark-green" [routerLinkActiveOptions]="{exact: true}" routerLink="{{'singleorder.organicwebshop_url' |translate}}"
            *ngIf="orderlines?.controls.length >0 && !is_in_cart" class="icon-round cursor-pointer pt-[3px] relative h-[35px] w-[35px] md:h-[45px] md:w-[45px] md:pt-[5px]"
            [ngClass]="{'bg-e-dark-green text-white': router.url.includes('/checkout')}" id="checkout" aria-label="Checkout">
            <i class="bi bi-bag text-[1.4em]"></i>
            <span class="text-[0.8em] top-[11px] right-[9px] w-[1.3em] absolute truncate text-center md:top-[15px] md:right-[14px]">{{orderlineTotalQuantity()}}</span>
            <div class="align-center absolute start-0 top-0 h-full w-full">
              <div class="inset-[-45%] w-[70px] h-[70px] absolute rounded-full bg-slate-400 md:inset-[-30%]" [@clickAnimation]="clickanimation"></div>
            </div>
          </a>
        </div>
        @if(show_userbuttons){
        @if((isAuthenticated | async)){
        <a class="icon-round h-[35px] w-[35px] md:h-[45px] md:w-[45px]" routerLink="/user" queryParamsHandling="merge" *ngIf="currentUser?.customerid" id="user"
          routerLinkActive="bg-e-dark-green" [routerLinkActiveOptions]="{exact: true}">
          <img src="./assets/images/icons/calendar_temp{{router.url.startsWith('/user') && !router.url.startsWith('/user/')? '_white' : ''}}.png" alt="calendar" height="25"
            width="25">
        </a>
        <a routerLink="/user/settings" class="icon-round h-[35px] w-[35px] md:h-[45px] md:w-[45px]" *ngIf="currentUser?.customerid" id="settings" aria-label="User"
          routerLinkActive="bg-e-dark-green" [routerLinkActiveOptions]="{exact: false}">
          <img src="/assets/images/icons/account{{router.url.startsWith('/user/') ? '_white' : ''}}.svg" width="25" height="25" alt="user profile" />
        </a>
        }@else{
        <a routerLink="/user" class="icon-round h-[35px] w-[35px] md:h-[45px] md:w-[45px]" id="user" aria-label="User" routerLinkActive="bg-e-dark-green"
          [routerLinkActiveOptions]="{exact: false}">
          <img src="/assets/images/icons/account{{router.url.startsWith('/user/') ? '_white' : ''}}.svg" width="25" height="25" alt="user profile" />
        </a>
        }
        }
      </div>
    </div>
  </div>
  <!-- <div  class="flex flex-row justify-center bg-red-600 text-white min-h-12 p-2">
    <span class="my-auto text-center">{{'common.website_in_maintenance'| translate}}</span>
  </div> -->

</div>